import React, {  } from "react"
import { Updates } from "src/assembled"
import { Footer } from "src/components"
import { SEO } from "src/components/utils"


export default function UpdatesPage({ location, navigate }) {
    // seo
    const seo = <SEO
    title="Updates"
    description="News, Pipelines, and Projects of Deargen Inc." 
    pathname={location.pathname} />

    
    // render
    const render = <main role="main">
        <Updates location={location} navigate={navigate} />
    </main>


    return <>
        {seo}
        {render}
        <Footer location={location} />
    </>
}
